import { Country } from "@sixty-six-north/i18n"
import { Currency } from "@sixty-six-north/i18n"
import { Language } from "@sixty-six-north/i18n"
import { countries, ICountry as LibCountry } from "countries-list"
import _pick from "lodash/pick"
import _sortBy from "lodash/sortBy"
import { AppConfig } from "../Config"
import { countryToPath } from "./Country"

export interface Region {
  country: Country
  language: Language
  currency: Currency
}

export const UK: Region = {
  country: Country.gb,
  language: Language.en,
  currency: Currency.GBP
}
export const US: Region = {
  country: Country.us,
  language: Language.en,
  currency: Currency.USD
}
export const EU: Region = {
  country: Country.eu,
  language: Language.en,
  currency: Currency.EUR
}
export const DK: Region = {
  country: Country.dk,
  language: Language.en,
  currency: Currency.DKK
}

export const IS: Region = {
  country: Country.is,
  language: Language.is,
  currency: Currency.ISK
}

export const REGIONS = [IS, DK, EU, UK, US]
export const REGION_PATH_COUNTRIES = REGIONS.map(r => countryToPath(r.country))

const getDefaultRegion = () => {
  if (AppConfig.isDevMode) {
    return UK
  } else {
    return US
  }
}

export const REGION_COUNTRIES = {
  UK: _pick(countries, "GB"),
  US: _pick(countries, "US", "CA"),
  IS: _pick(countries, "IS"),
  DK: _pick(countries, "DK"),
  EU: _pick(
    countries,
    "AT",
    "BE",
    "BG",
    "HR",
    "CH",
    "CY",
    "CZ",
    "EE",
    "FI",
    "FR",
    "DE",
    "GR",
    "HU",
    "IE",
    "IT",
    "LI",
    "LV",
    "LT",
    "LU",
    "MT",
    "NL",
    "NO",
    "PL",
    "PT",
    "RO",
    "SK",
    "SI",
    "ES",
    "SE"
  ),
  ALL: countries
}

export const DEFAULT_REGION = getDefaultRegion()

export const getCountryForRegion = region => {
  if (region.country === UK.country) {
    {
      return REGION_COUNTRIES.UK
    }
  } else if (region.country === DK.country) {
    {
      return REGION_COUNTRIES.DK
    }
  } else if (region.country === EU.country) {
    {
      return REGION_COUNTRIES.EU
    }
  } else if (region.country === IS.country) {
    {
      return REGION_COUNTRIES.IS
    }
  } else if (region.country === US.country) {
    {
      return REGION_COUNTRIES.US
    }
  } else {
    {
      return REGION_COUNTRIES.ALL
    }
  }
}

export const getSupportedCountriesForRegion = region => {
  return Object.entries(getCountryForRegion(region))
    .map(
      ([key, country]) =>
        ({
          code: key,
          country
        }) as CountryWithCodeI
    )
    .sort((left, right) => {
      return left.country.name >= right.country.name ? 1 : -1
    })
}

export const getRegionForCountry = (country: Country | string): Region => {
  switch (country) {
    case Country.gb: {
      return UK
    }
    case Country.us: {
      return US
    }
    case Country.eu: {
      return EU
    }
    case Country.dk: {
      return DK
    }
    case Country.is: {
      return IS
    }
    default: {
      return DEFAULT_REGION
    }
  }
}

export interface CountryWithCodeI {
  code: string
  country: LibCountry
}

export const defaultSupportedCountries = _sortBy(
  Object.entries(countries),
  it => it[1].name
).map(([key, country]) => ({ code: key, country }) as CountryWithCodeI)
